<template>
  <section>
    <b-row class="invoice-add">
      <b-col
          cols="12"
          xl="12"
          md="12"
      >
        <b-form @submit.prevent>
          <b-card-actions
              ref="yeni-alis"
              no-body
              class="invoice-preview-card"
          >
            <b-row class="pt-2 pl-2 pr-2 pb-0">
              <b-col cols="12" class="d-flex">
                <h3>Yeni Alış</h3>
                <b-badge v-if="isIhale" variant="light-primary" class="ihale-badgee">
                  İhale
                </b-badge>
              </b-col>
            </b-row>
            <hr class="invoice-spacing m-0">
            <b-card-body
                class="invoice-padding pt-0 pb-0 mt-2"
            >
              <b-row>
                <b-col xl="7" xs="12" sm="12" cols="12" class="d-flex justify-content-start">
                  <b-row class="w-100">
                    <b-col lg="6" md="12" sm="12" cols="12" class="p-0">
                      <b-form-group label="Cari Kart" label-for="basicInput" class="d-inline-block cari-adi">
                        <v-select
                            v-model="fatura.cariKart"
                            :options="cariKartlar"
                            :getOptionLabel="option => option.ad + ' ' + option.soyad + ' - ' + option.firmaUnvan"
                            input-id="invoice-data-client"
                            :clearable="false"
                            @search="(search, loading) => { loading(true)
                         $store.dispatch('cariCardlar', {
                            uzunluk: 20,
                            baslangic: 0,
                            cariGrupID: 0,
                            searchKey: search,
                          }).then(() => loading(false))
                        }"
                        >
                          <template #selected-option-container="{ option }">
                            <div class="vs__selected">
                              {{
                                option.firmaUnvan ? option.firmaUnvan.toUpperCase() : `${option.adi} ${option.soyAdi}`
                              }}
                              <!--                              <b-badge :variant="option.firmaUnvan ? 'light-success':'light-primary'" class="ml-2">-->
                              <!--                                {{ option.firmaUnvan ? 'Kurumsal' : 'Bireysel' }}-->
                              <!--                              </b-badge>-->
                            </div>
                          </template>
                          <template #option="{ firmaUnvan, adi,soyAdi ,cariKartID}">
                            {{ firmaUnvan ? firmaUnvan.toUpperCase() : `${adi} ${soyAdi}` }}
                          </template>
                          <template #list-header>
                            <li
                                v-b-modal.new-cari
                                class="add-new-client-header d-flex align-items-center my-50"
                            >
                              <feather-icon
                                  icon="PlusIcon"
                                  size="16"
                              />
                              <span class="align-middle ml-25">Yeni Cari Kart</span>
                            </li>
                          </template>
                        </v-select>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="false" lg="3" md="6" sm="6" cols="6">
                      <b-form-group label="Tahsilat Tarihi" label-for="basicInput" class="d-inline-block w-100">
                        <flat-pickr
                            v-model="fatura.tahsilatTarihi"
                            class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="3" md="6" sm="6" cols="6" class="p-0">
                      <b-form-group label="Düzenlenme Tarihi" label-for="basicInput" class="d-inline-block w-100 ml-2">
                        <flat-pickr
                            v-model="fatura.duzenlemeTarihi"
                            class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="false" xl="5" xs="12" sm="12" cols="12">
                  <b-form-group label="Sipariş Notu" label-for="basicInput">
                    <b-form-textarea v-model="fatura.notu"/>
                  </b-form-group>
                </b-col>
                <b-col xl="7" xs="12" sm="12" cols="12" class="d-flex justify-content-start">
                  <!--                  <b-form-group-->
                  <!--                      label="Sipariş No"-->
                  <!--                      label-for="basicInput"-->
                  <!--                      class="mr-50"-->
                  <!--                      :class="width < 500 ? 'w-100': width < 1200 ? 'w-50':''"-->
                  <!--                  >-->
                  <!--                    <b-form-input-->
                  <!--                        id="siparis-no"-->
                  <!--                        v-model="fatura.siparisNo"-->
                  <!--                        autocomplete="off"-->
                  <!--                    />-->
                  <!--                  </b-form-group>-->
                  <!--                  <b-form-group-->
                  <!--                      label="Sıra No"-->
                  <!--                      label-for="basicInput"-->
                  <!--                      class="w-25 mr-50"-->
                  <!--                  >-->
                  <!--                    <b-form-input-->
                  <!--                        id="basicInput"-->
                  <!--                        v-model="fatura.siraNo"-->
                  <!--                        autocomplete="off"-->
                  <!--                    />-->
                  <!--                  </b-form-group>-->
                  <!--                  <b-form-group-->
                  <!--                      label="Fatura No"-->
                  <!--                      label-for="basicInput"-->
                  <!--                      class="w-25"-->
                  <!--                  >-->
                  <!--                    <b-form-input-->
                  <!--                        id="basicInput"-->
                  <!--                        v-model="fatura.faturaNo"-->
                  <!--                        autocomplete="off"-->
                  <!--                    />-->
                  <!--                  </b-form-group>-->
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-body
                class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">
                <b-col
                    cols="12"
                    xl="6"
                    class="p-0"
                >
                  <h6 class="mb-1" v-if="fatura.cariKart">
                    Cari Kart Bilgileri
                  </h6>
                  <h6 class="mb-25" v-if="fatura.cariKart.firmaUnvan">
                    Firma Unvanı: <strong>{{ `${fatura.cariKart.firmaUnvan}` }}</strong>
                  </h6>
                  <p class="card-text mb-25" v-if="fatura.cariKart.adi">
                    Adı: <strong>
                    {{ `${fatura.cariKart.adi} ${fatura.cariKart.soyAdi}` }}
                  </strong>
                  </p>
                  <p class="card-text mb-25" v-if="fatura.cariKart.telefon">
                    telefon : <strong>
                    {{ `${fatura.cariKart.telefon}` }}
                  </strong>
                  </p>
                  <p class="card-text mb-25" v-if="fatura.cariKart.ePosta">
                    ePosta : <strong>
                    {{ `${fatura.cariKart.ePosta}` }}
                  </strong>
                  </p>
                  <p class="card-text mb-0" v-if="fatura.cariKart.adres">
                    Adres: <strong>
                    {{ fatura.cariKart.adres }}
                  </strong>
                  </p>
                </b-col>
                <b-col
                    xl="6"
                    cols="12"
                    class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div v-if="fatura.cariKart.firmaUnvan">
                    <h6 class="mb-2">
                      Ödeme Bilgileri
                    </h6>
                    <table>
                      <tbody>
                      <tr>
                        <td class="pr-1">
                          Vergi Dairesi:
                        </td>
                        <td><span class="font-weight-bold">   {{ fatura.cariKart.vergiDairesi }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Vergi Numarası:
                        </td>
                        <td> {{ fatura.cariKart.vergiNumarasi }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Banka:
                        </td>
                        <td> {{ fatura.cariKart.banka }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          İBAN
                        </td>
                        <td> {{ fatura.cariKart.ibann }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <hr/>
            <b-card-body class="invoice-padding form-item-section">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateAgain"
                  :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important',border:'none'}"
              >
                <font-awesome-icon icon="fa-solid fa-plus" class="mr-50"/>
                <span>Satır Ekle</span>
              </b-button>
              <b-dropdown
                  v-if="false"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  variant="outline-dark"
                  no-caret
                  class="ml-50 mr-50"
              >
                <template v-slot:button-content>
                  <font-awesome-icon icon="fa-solid fa-plus"/>
                  Vergi Ekle
                </template>
                <b-dropdown-item v-for="(vergi,i) in vergiListesi" :key="i"
                                 @click="addTax(vergi.vergiKodu,vergi.vergiOrani,vergi.vergiAdi)"
                >
                  {{ vergi.vergiAdi }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                  class="ml-1"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeItem"
                  :class="width < 551 ? 'mt-50':''"
              >
                <font-awesome-icon icon="fa-solid fa-xmark"/>
                Seçili Satırları Sil
              </b-button>
              <table class="table mt-1" ref="form" :class="width  < 900 ?'table-responsive':''">
                <thead>
                <tr>
                  <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important'}"
                      style="width: 1%"
                  ></th>
                  <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important'}"
                      style="width: 20%"
                  >Ürün Adı
                  </th>
                  <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important'}"
                      style="width: 10%"
                  >Miktar
                  </th>
                  <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important'}"
                      style="width: 10%"
                  >Birim
                  </th>
                  <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important'}"
                      style="width: 10%"
                  >Birim Fiyat
                  </th>
                  <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important'}"
                      style="width: 10%"
                  >Tutar
                  </th>
                  <th v-for="(vergi,index) in eklenenVergiler" :key="index"
                      :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important'}"
                      style="width: 10%"
                  > {{
                      getVergi(vergi).name
                    }}
                  </th>
                  <th v-if="false"
                      :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important'}"
                      style="width: 10%"
                  >Stoğa Kaydet
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(urun, index) in urun" :key="urun.id" ref="row">
                  <td>
                    <b-form-checkbox
                        :value="urun.id"
                        v-model="itemSelected[index]"
                        class="custom-control-primary d-inline-block item-checkbox"
                    />
                  </td>
                  <td>
                    <v-select
                        v-model="urun.urunAdi"
                        @search="fetchOptions"
                        :options="stokListesi"
                        label="stokAdi"
                        ref="stokSelect"
                        class="style-chooser min-w-100"
                        @input="yeniStok($event , index)"
                    >
                      <template #selected-option-container="{ option }">
                        <div class="vs__selected v-select-selected-xl" v-if="window.width > 1320">
                          {{
                            option.stokAdi.length > 30 ? option.stokAdi.slice(0, 30) + '...' : option.stokAdi
                          }}
                        </div>
                        <div v-else-if="window.width <= 1320 && window.width > 850">
                          {{
                            option.stokAdi.length > 15 ? option.stokAdi.slice(0, 15) + '...' : option.stokAdi
                          }}
                        </div>
                        <div v-else-if="window.width <= 850 && window.width > 665">
                          {{
                            option.stokAdi.length > 10 ? option.stokAdi.slice(0, 10) + '...' : option.stokAdi
                          }}
                        </div>
                        <div v-else-if="window.width <= 665">
                          {{
                            option.stokAdi.length > 5 ? option.stokAdi.slice(0, 5) + '...' : option.stokAdi
                          }}
                        </div>
                      </template>
                    </v-select>
                  </td>
                  <td>
                    <b-form-input
                        type="number"
                        v-model="urun.miktar"
                        autocomplete="off"
                        @input="miktarChange($event,index)"
                        size="sm"
                        class="min-w-100"
                    />
                  </td>
                  <td>
                    <b-form-select v-model="urun.birim" :options="birimOptions" placeholder="birim türünü seçiniz"
                                   autocomplete="off" size="sm" class="min-w-100"
                    />
                  </td>
                  <td>
                    <b-form-input
                        id="birim-fiyat"
                        type="number"
                        v-model="urun.birimFiyat"
                        autocomplete="off"
                        @input="birimFiyatChange($event,index)"
                        size="sm"
                        class="min-w-100"
                    />
                  </td>
                  <td>
                    <b-form-input
                        type="number"
                        v-model="urun.tutar"
                        autocomplete="off"
                        @input="tutarChange($event,index)"
                        size="sm"
                        class="min-w-100"
                    />
                  </td>
                  <td v-for="vergi in eklenenVergiler" :key="vergi">
                    <b-form-input
                        :id="vergi"
                        type="number"
                        v-model="urun.vergiler[getVergi(vergi).name]"
                        autocomplete="off"
                        size="sm"
                        class="min-w-100"
                    />
                  </td>
                  <td v-if="false">
                    <b-form-checkbox
                        checked="true"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        v-model="urun.isStok"
                    >
                      <span class="switch-icon-left">
                          <font-awesome-icon icon="fa-solid fa-check"/>
                        </span>
                      <span class="switch-icon-right">
                          <font-awesome-icon icon="fa-solid fa-xmark"/>
                        </span>
                    </b-form-checkbox>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-card-body>
            <hr/>
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <b-col
                    cols="12"
                    md="5"
                    class="mt-md-0 mt-3 d-flex"
                    order="1"
                    order-md="1"
                >
                  <b-form-group label="Sipariş Notu" label-for="basicInput" class="w-100">
                    <b-form-textarea v-model="fatura.aciklama"/>
                  </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="7"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Ara Toplam:
                      </p>
                      <p class="invoice-total-amount" v-if="araToplam">
                        {{ araToplam }} TL
                      </p>
                    </div>
                    <div class="invoice-total-item" v-for="(vergi,index) in vergiData" :key="index">
                      <p class="invoice-total-title" v-if="vergi.toplamVergi">
                        {{ vergi.vergiAdi }}
                        <small>({{ vergi.vergiOrani }}%)</small>:
                      </p>
                      <p class="invoice-total-amount text-nowrap" v-if="vergi.toplamVergi">
                        {{
                          Number(vergi.toplamVergi)
                        }}
                        <font-awesome-icon icon="turkish-lira-sign"/>
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Toplam Tutar:
                      </p>
                      <p class="invoice-total-amount" v-if="toplamTutar">
                        {{ toplamTutar }} TL
                      </p>
                    </div>
                    <div class="invoice-total-item mt-3">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          block
                          variant="success"
                          @click="faturaCreated"
                          :disabled="kaydetBtn"
                          :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                      >
                        <b-spinner v-if="kaydetBtn" small/>
                        Sipariş Fişini Kaydet
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <hr class="invoice-spacing">
          </b-card-actions>
        </b-form>
      </b-col>
    </b-row>
    <b-modal
        id="new"
        ok-only
        hide-footer
        hide-header
        centered
        size="lg"
        header-bg-variant="white"
        no-close-on-esc
        no-close-on-backdrop
    >
      <b-row>
        <b-col cols="12">
          <h3>
            <b-badge variant="success" class="modalBadge">
              Yeni Stok Kaydı
            </b-badge>
          </h3>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Stok Adı" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.name" id="stok-adi"/>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Stok Grubu" label-for="basicInput">
            <model-list-select
                class=""
                v-model="addItem.grupID"
                :list="groupGet"
                option-value="stokGrupID"
                option-text="grupAdi"

                placeholder="Stok Grubunu Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Alış Fiyat" label-for="basicInput">
            <b-form-input type="number" autocomplete="off" v-model="addItem.alisFiyat" id="alis-fiyat"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Birim Fiyatı" label-for="basicInput">
            <b-form-input type="number" autocomplete="off" v-model="addItem.birimFiyat" id="birim-fiyati"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="KDV" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.kdv" id="kdv"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Birim" label-for="basicInput">
            <b-form-select v-model="addItem.birim" :options="birimOptions" placeholder="Stok Grubunu Seçiniz"/>
          </b-form-group>
        </b-col>
        <b-col cols="4" class="mt-1">
          <b-form-group label="Barkod" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.barkod" id="barkod"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group :label="addItem.birim" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addItem.adet" id="adet"/>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="mt-1">
          <b-form-group id="stoksuz-satis-label" label="Stoksuz Satış" label-for="basicInput">
            <b-form-checkbox
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
                autocomplete="off"
                v-model="addItem.stoksuzSatis"
            >
              <span class="switch-icon-left">
                <font-awesome-icon icon="fa-solid fa-check"/>
              </span>
              <span class="switch-icon-right">
                    <font-awesome-icon icon="fa-solid fa-xmark"/>
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
              @click="add"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-50"
          >
            Kaydet
          </b-button>
          <b-button
              @click="newReset"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              class="mr-50"
          >
            Sıfırla
          </b-button>
          <b-button
              @click="newHide"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
          >
            Kapat
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="new-cari"
        ok-only
        hide-footer
        centered
        size="lg"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
    >
      <b-row>
        <b-col cols="12">
          <h3>
            <b-badge variant="primary" class="modalBadge">
              Yeni Cari Kart
            </b-badge>
          </h3>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="İsim" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addCari.adi" id="isim"/>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Soyisim" label-for="soy-isim">
            <b-form-input autocomplete="off" v-model="addCari.soyAdi" id="soy-isim"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Cari Grup" label-for="basicInput">
            <model-list-select
                class=""
                v-model="addCari.cariGrupID"
                :list="cariGroupGet"
                option-value="cariGrupID"
                option-text="grupAdi"

                placeholder="Cari Grup Seçiniz"
            >
            </model-list-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="E-Posta" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addCari.ePosta" id="e-posta"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Telefon" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addCari.telefon" id="telefon"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="İl"
              label-for="basicInput"
          >
            <model-list-select
                class=""
                :list="json"
                v-model="addCari.il"
                option-value="il_adi"
                option-text="il_adi"

                name="unvan"
                placeholder="İl Seçiniz"
            >
            </model-list-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="İlçe"
              label-for="basicInput"
          >
            <model-list-select
                class=""
                :list="ilceler"
                v-model="addCari.ilce"
                option-value="ilce_adi"
                option-text="ilce_adi"

                placeholder="İlçe Seçiniz"
            >
            </model-list-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Adres" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addCari.adres" id="adres"/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Cari Not" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="addCari.cariNot" id="cari-not"/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <h3>
            <b-badge variant="success" class="modalBadge">
              Fatura Bilgileri
            </b-badge>
          </h3>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Firma Ünvanı"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input autocomplete="off" v-model="addCari.firmaUnvan" id="firma-unvan"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Vergi No / TC NO"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input autocomplete="off" maxLength="12" v-model="addCari.vergiNumarasi" id="vergi-no"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Banka"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input autocomplete="off" v-model="addCari.banka" id="banka"/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="İBAN"
              label-for="basicInput"
              class="mt-1"
          >
            <b-input-group @paste="ibanPaste">
              <b-input-group-prepend is-text>
                TR
              </b-input-group-prepend>
              <cleave
                  v-model="addCari.ibann"
                  class="form-control"
                  :options="options.block"
                  placeholder="00 0000 0000 0000 0000 0000 00"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Vergi Dairesi"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input autocomplete="off" v-model="addCari.vergiDairesi" id="vergi-dairesi"/>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-50"
              @click="addCari.adi && addCari.soyAdi && addCari.cariGrupID && (addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? cariAdd() : false"
              :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none',cursor: !addCari.adi || !addCari.soyAdi || !addCari.cariGrupID || !(addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? 'default':'pointer',opacity: !addCari.adi || !addCari.soyAdi || !addCari.cariGrupID || !(addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? 0.5: 1}"
              v-b-tooltip="!addCari.adi ? 'Cari Adı Zorunludur.' :  !addCari.soyAdi  ?'Cari Soyismi Zorunludur.' : !addCari.cariGrupID ? 'Cari Grubu Zorunludur.': !(addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? 'Vergi numarası 10 haneli veya TC Kimlik numarası 11 haneli olmalıdır.':'' "
          >
            Kaydet
          </b-button>
          <b-button
              @click="newCariReset"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mr-50"
              variant="outline-warning"
          >
            Sıfırla
          </b-button>
          <b-button
              @click="newCariKartHide"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
          >
            Kapat
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-sidebar
        id="add-stok-grup"
        :visible="isNewGroup"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        right
        @change="(val) => $emit('update:is-add-stok-grup-active', val)"
        ref="newGrup"
    >
      <template>
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Yeni Stok Grubu Ekle
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="newGroupCancel"
          />
        </div>
        <b-form @submit.prevent id="sidebar-form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Grup Adı"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="grupName"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="!grupName"
                  @click="grupSave('kayit')"
              >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                Kaydet
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="newGroupCancel"
              >
                Vazgeç
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
    <b-sidebar
        id="add-cari-grup"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        right
        @change="(val) => $emit('update:is-add-cari-grup-active', val)"
        ref="newCariGrup"
    >
      <template>
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Cari Grup Ekle
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="newCariGrupHidden"
          />
        </div>
        <b-form @submit.prevent id="new-cari-form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Grup Adı"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="grupName"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Açıklama"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="AlignCenterIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="grupContent"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Grup Türü"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <model-list-select
                      class=""
                      :list="grupTuruOptions"
                      v-model="grupTuru"
                      option-value="value"
                      option-text="text"

                      name="unvan"
                      placeholder="Hızlı Seçim"
                  >
                  </model-list-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="!grupName || !grupTuru"
                  @click="cariGrupSave('kayit')"
              >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                Kaydet
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="newCariGrupHidden"
              >
                Vazgeç
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSpinner,
  BImg,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BFormTextarea,
  BCard,
  BCardText,
  BCardBody,
  BPopover,
  BModal,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  VBToggle,
  BInputGroupPrepend,
  BForm,
  BButton,
  BFormInvalidFeedback, BFormValidFeedback,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import 'vue-search-select/dist/VueSearchSelect.css'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { useWindowSize } from '@vueuse/core'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
import Cleave from 'vue-cleave-component'

export default {
  setup() {
    const json = require('@/assets/turkey-cities.json').data
    const ilceler = []
    const { invoiceLogo } = $themeConfig.app
    const {
      width,
    } = useWindowSize()
    return {
      json,
      ilceler,
      invoiceLogo,
      width,
    }
  },
  components: {
    Cleave,
    BSpinner,
    BImg,
    BFormInvalidFeedback,
    BFormValidFeedback,
    vSelect,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    flatPickr,
    BFormTextarea,
    BCard,
    BCardBody,
    BPopover,
    BCardText,
    BSidebar,
    BPagination,
    BButton,
    BForm,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BTable,
    BBadge,
    BRow,
    BCol,
    BInputGroupAppend,
    BInputGroupPrepend,
    BModal,
    BCardActions,
    ModelListSelect,
  },
  mixins: [heightTransition],
  data() {
    return {
      options: {
        block: {
          blocks: [2, 4, 4, 4, 4, 4, 2],
          uppercase: true,
        },
      },
      window: {
        width: 0,
        height: 0,
      },
      vergiOranlari: {
        '0015': '20%',
      },
      eklenenVergiler: ['0015'],
      urun: [{
        id: 1,
        urunAdi: '',
        tutar: 0,
        miktar: 0,
        birim: 'Adet',
        birimFiyat: 0,
        isStok: true,
        vergiler: {
          // '0015': 20,
          KDV: 20,
        },
      }],
      taxColumns: [],
      itemSelected: [],
      kdvID: '0015',
      kaydetBtn: false,
      vergiData: [],
      araToplam: '',
      toplamTutar: '',
      actionIndex: '',
      grupName: '',
      grupContent: '',
      isNewGroup: false,
      formCount: 0,
      nextTodoId: 2,
      fatura: {
        eFatura: false,
        faturaNo: '',
        siraNo: '',
        notu: '',
        siparisNo: '',
        aciklama: '',
        cariKart: '',
        tahsilatTarihi: '',
        duzenlemeTarihi: '',
      },
      addCari: {
        cariGrupID: '',
        cariCardID: '',
        adi: '',
        soyAdi: '',
        adres: '',
        ePosta: '',
        telefon: '',
        firmaUnvan: '',
        vergiNumarasi: '',
        vergiDairesi: '',
        il: '',
        ilce: '',
        cariNot: '',
        banka: '',
        ibann: '',
      },
      birimOptions: ['Adet', 'Koli', 'Paket', 'Kutu', 'Kg', 'Gram', 'Litre', 'Ton', 'Net Ton', 'Gross Ton', 'Yıl', 'Saat', 'Dakika', 'Saniye', 'mm', 'cm', 'Hücre Adet', 'Cift', 'Set', 'Düzine', 'Brüt Kalori'],
      addItem: {
        name: '',
        grupID: '',
        adet: '',
        birim: 'Adet',
        birimFiyat: '',
        kdv: '',
        alisFiyat: '',
        stoksuzSatis: false,
        barkod: '',
      },
      grupTuruOptions: [
        {
          value: '1',
          text: 'Müşteriler',
        }, {
          value: '2',
          text: 'Tedarikçiler',
        }, {
          value: '3',
          text: 'Personeller',
        }, {
          value: '4',
          text: 'Diğer',
        },
      ],
      grupTuru: {
        value: '1',
        text: 'Müşteriler',
      },
    }
  },
  watch: {
    urun: {
      deep: true,
      handler(newVal) {
        this.faturaPreview()
      },
    },
    addCari: {
      deep: true,
      handler(newVal) {
        if (newVal.cariGrupID == -1) {
          this.$bvModal.hide('new-cari')
          this.$root.$emit('bv::toggle::collapse', 'add-cari-grup')
        }
        // todo SEHIR_ATAMA
        if (newVal.il) {
          this.ilceler = this.json.find(x => x.il_adi === newVal.il).ilceler
        }
      },
    },
    addItem: {
      deep: true,
      handler(newVal) {
        if (newVal.grupID == -1) {
          this.$root.$emit('bv::toggle::collapse', 'add-stok-grup')
          this.$bvModal.hide('new')
          this.isNewGroup = true
        }
      },
    },
  },
  computed: {
    isIhale() {
      return !!localStorage.getItem('associateID')
    },
    tableColumns() {
      return ['#', 'Name', 'Price', ...this.taxColumns, 'Handle']
    },
    stokListesi() {
      let grupList = []
      grupList.push(Object.values(store.getters.STOK_LIST_GET))
      grupList[0].push({
        stokAdi: 'Yeni Stok Oluştur',
        stokID: '-1',
        yeni: true,
      })
      return _.orderBy(grupList[0], ['yeni', 'adet'], ['asc', 'desc'])
    },
    vergiListesi() {
      return store.getters.VERGI_LISTESI_GET
    },
    cariKartlar() {
      return Object.values(store.getters.CARI_CARD_GET)
    },
    groupGet() {
      let grupList = []
      grupList.push(Object.values(store.getters.STOK_GROUP_GET))
      grupList[0].push({
        grupAdi: 'Yeni Stok Grubu Oluştur',
        stokGrupID: '-1',
        yeni: true,
      })
      return _.orderBy(grupList[0], ['yeni', 'grupAdi'], ['asc', 'desc'])
    },
    cariGroupGet() {
      let grupList = []
      grupList.push(Object.values(store.getters.CARI_GROUP_GET))
      grupList[0].push({
        grupAdi: 'Yeni Cari Grup Oluştur',
        cariGrupID: '-1',
        yeni: true,
      })
      return _.orderBy(grupList[0], ['yeni', 'toplamCari'], ['asc', 'desc'])
    },
  },
  methods: {
    ibanPaste(event) {
      const veri = event.clipboardData.getData('text')
      if (veri.includes('TR')) {
        this.addCari.ibann = veri.slice(2, 32)
      } else {
        this.addCari.ibann = veri.slice(0, 40)
      }
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    vergiFind(vergiAdi) {
      const veri = Object.values(this.vergiListesi)
          .find(x => x.vergiAdi == vergiAdi || '')
      return veri.vergiKodu
    },
    getVergi(vergiKodu) {
      const veri = Object.values(this.vergiListesi)
          .find(x => x.vergiKodu == vergiKodu || '')
      return {
        name: veri?.vergiAdi,
      }
    },
    addVergiData(kod, oran) {
      this.vergiData.push({
        kodID: kod,
        vergiOrani: oran,
      })
    },
    miktarChange(event, i) {
      const item = this.urun[i]
      item.tutar = Math.round((item.birimFiyat * event) * 10000) / 10000
    },
    birimFiyatChange(event, i) {
      const item = this.urun[i]
      item.tutar = Math.round((event * item.miktar) * 10000) / 10000
    },
    tutarChange(event, i) {
      const item = this.urun[i]
      item.birimFiyat = Math.round((event / item.miktar) * 10000) / 10000
    },
    yeniStok(val, index) {
      this.actionIndex = index
      if (val.yeni === true) {
        this.$bvModal.show('new')
      }
      const item = this.urun[index]
      item.miktar = val.adet != 0 ? 1 : ''
      item.birimFiyat = val.birimFiyat
      item.birim = val.birim
      item.tutar = item.birimFiyat * item.miktar
    },
    faturaCreated() {
      this.kaydetBtn = true
      axios.post(store.state.POST_URL, this.postSchema(), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              localStorage.removeItem('associateID')
              router.push({
                name: 'fisDetay',
                params: { id: res.data.faturaData.siparisFisOzeti.fisID },
              })
              const mesaj = res.data.result.errMsg
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
              store.commit('setToken', res.data.userToken)
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'warning',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .finally(() => {
            this.kaydetBtn = false
          })
    },
    faturaPreview() {
      const schema = this.postSchema()
      schema.set('serviceName', 'fatura')
      schema.set('methodName', 'faturaGetData')
      return axios.post(store.state.POST_URL, schema, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then(res => {
            this.araToplam = Number(res.data.araToplam)
            this.toplamTutar = Number(res.data.toplamTutar)
            this.vergiData = res.data.vergiData
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'depo')
      fd.append('methodName', 'siparisFisKaydet')
      fd.append('faturaData[cariKartID]', this.fatura.cariKart.cariKartID)
      // fd.append('faturaData[aciklama]', this.fatura.aciklama)
      fd.append('faturaData[faturaNot]', this.fatura.notu)
      fd.append('faturaData[faturaTuru]', 2)
      // fd.append('faturaData[tahsilatTarih]', this.fatura.tahsilatTarihi)
      fd.append('faturaData[duzenlenmeTarih]', this.fatura.duzenlemeTarihi)
      if (localStorage.getItem('associateID')) {
        fd.append('faturaData[ihaleID]', localStorage.getItem('associateID'))
      }
      // fd.append('faturaData[siraNo]', this.fatura.siraNo)
      // fd.append('faturaData[faturaNo]', this.fatura.faturaNo)
      // fd.append('faturaData[siparisNo]', this.fatura.siparisNo || '')
      this.urun.forEach((item, index) => {
        // fd.append(`faturaData[birimListesi][${index}][stokEkle]`, item.isStok == true ? 1 : 0)
        fd.append(`faturaData[birimListesi][${index}][urunAdi]`, item.urunAdi.stokAdi || '')
        fd.append(`faturaData[birimListesi][${index}][adet]`, item.miktar || 0)
        fd.append(`faturaData[birimListesi][${index}][birim]`, item.birim || 0)
        fd.append(`faturaData[birimListesi][${index}][tutar]`, item.birimFiyat || 0)
        fd.append(`faturaData[birimListesi][${index}][stokID]`, item.urunAdi.stokID || '')
        fd.append(`faturaData[birimListesi][${index}][kdv]`, item.vergiler.KDV || '')
        Object.entries(item.vergiler)
            .forEach((vergi, i) => {
              const [key, value] = vergi
              fd.append(`faturaData[birimListesi][${index}][vergiListesi][${i}][vergiKodu]`, this.vergiFind(key))
              fd.append(`faturaData[birimListesi][${index}][vergiListesi][${i}][vergiOrani]`, value || 0)
            })
      })
      return fd
    },
    fetchOptions(search) {
      store.dispatch('stokList', {
        searchKey: search,
        uzunluk: 20,
        baslangic: 0,
      })
    },
    addTax(vergiKodu, vergiOrani, vergiAdi) {
      if (this.eklenenVergiler.includes(vergiKodu) == false) {
        this.$set(this.vergiOranlari, vergiKodu, vergiOrani)
        this.eklenenVergiler.push(vergiKodu)
        this.urun.forEach(urun => {
          this.$set(urun.vergiler, vergiAdi, vergiOrani)
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata!',
            text: 'Vergi zaten ekli durumda',
            icon: 'ThumbsDownIcon',
            variant: 'warning',
          },
        })
      }
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    vergiRemove(index, vergiKodu) {
      const item = this.urun[index]
      delete item.vergiler[vergiKodu]
      this.formCount++
      this.faturaPreview()
    },
    repeateAgain() {
      this.urun.push({
        id: this.nextTodoId += this.nextTodoId,
        urunAdi: '',
        miktar: 0,
        birim: 'Adet',
        birimFiyat: 0,
        isStok: true,
        vergiler: {
          // '0015': 20,
          KDV: 20,
        },
        tutar: 0,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      Object.entries(this.itemSelected)
          .forEach(entry => {
            const [key, value] = entry
            const findIndex = this.urun.findIndex(x => x.id === value)
            if (findIndex !== -1) {
              this.urun.splice(findIndex, 1)
              this.trTrimHeight(this.$refs.row[0].offsetHeight)
            }
          })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    add() {
      store.dispatch('stokSave', {
        stokAdi: this.addItem.name,
        stokGrupID: this.addItem.grupID,
        adet: this.addItem.adet,
        birim: this.addItem.birim,
        birimFiyat: this.addItem.birimFiyat.replace(',', '.'),
        kdv: this.addItem.kdv,
        alisFiyat: this.addItem.alisFiyat.replace(',', '.'),
        stoksuzSatis: this.addItem.stoksuzSatis,
        barkod: this.addItem.barkod,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.urun[this.actionIndex].urunAdi = res.data || ''
              this.urun[this.actionIndex].miktar = res.data.adet || 0
              this.urun[this.actionIndex].birim = res.data.birim || 'Adet'
              this.urun[this.actionIndex].birimFiyat = res.data.birimFiyat || 0
              this.urun[this.actionIndex].tutar = res.data.birimFiyat * res.data.adet || 0
              this.newReset()
              this.$bvModal.hide('new')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
              setTimeout(() => {
                this.$refs.stokSelect.forEach(item => {
                  item.$refs.search.blur()
                })
              }, 300)
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newReset() {
      this.addItem.name = ''
      this.addItem.grupID = ''
      this.addItem.adet = ''
      this.addItem.birim = 'Adet'
      this.addItem.birimFiyat = ''
      this.addItem.kdv = ''
      this.addItem.alisFiyat = ''
      this.addItem.stoksuzSatis = false
      this.addItem.barkod = ''
    },
    newGroupCancel() {
      this.addItem.grupID = ''
      this.$bvModal.show('new')
      this.grupName = ''
      this.isNewGroup = false
    },
    grupSave(islem) {
      store.dispatch('stokGroupSave', {
        adi: islem === 'kayit' ? this.grupName : '',
        islem,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.addItem.grupID = res.data.stokGrupID
              this.$bvModal.show('new')
              this.$refs.newGrup.hide()
              this.grupName = ''
              if (res.result.errMsg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: res.result.errMsg,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            this.addItem.stokGrupID = ''
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newHide() {
      this.urun[this.actionIndex].urunAdi = ''
      this.$bvModal.hide('new')
      const t = this
      t.addItem.name = ''
      t.addItem.grupID = ''
      t.addItem.adet = ''
      t.addItem.birim = 'Adet'
      t.addItem.birimFiyat = ''
      t.addItem.kdv = ''
      t.addItem.alisFiyat = ''
      t.addItem.stoksuzSatis = false
      t.addItem.barkod = ''
    },
    cariAdd() {
      store.dispatch('cariCardSave', {
        adi: this.addCari.adi,
        soyAdi: this.addCari.soyAdi,
        adres: this.addCari.adres,
        ePosta: this.addCari.ePosta,
        telefon: this.addCari.telefon,
        faturaUnvan: this.addCari.firmaUnvan,
        vergiDairesi: this.addCari.vergiDairesi,
        vergiNumarasi: this.addCari.vergiNumarasi,
        bakiye: this.addCari.bakiye,
        cariNot: this.addCari.cariNot,
        il: this.addCari.il,
        ilce: this.addCari.ilce,
        banka: this.addCari.banka,
        ibann: this.addCari.ibann,
        cariGrupID: this.addCari.cariGrupID,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.fatura.cariKart = res.data
              this.newCariReset()
              this.$bvModal.hide('new-cari')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newCariKartHide() {
      this.$bvModal.hide('new-cari')
      const t = this
      t.addCari.adi = ''
      t.addCari.soyAdi = ''
      t.addCari.adres = ''
      t.addCari.ePosta = ''
      t.addCari.telefon = ''
      t.addCari.firmaUnvan = ''
      t.addCari.vergiDairesi = ''
      t.addCari.vergiNumarasi = ''
      t.addCari.il = ''
      t.addCari.ilce = ''
      t.addCari.cariNot = ''
      t.addCari.banka = ''
      t.addCari.ibann = ''
    },
    newCariReset() {
      this.addCari.adi = ''
      this.addCari.soyAdi = ''
      this.addCari.adres = ''
      this.addCari.ePosta = ''
      this.addCari.telefon = ''
      this.addCari.firmaUnvan = ''
      this.addCari.vergiDairesi = ''
      this.addCari.vergiNumarasi = ''
      this.addCari.il = ''
      this.addCari.ilce = ''
      this.addCari.cariNot = ''
      this.addCari.banka = ''
      this.addCari.ibann = ''
    },
    newCariGrupHidden() {
      this.addCari.cariGrupID = ''
      this.$refs.newCariGrup.hide()
      this.$bvModal.show('new-cari')
    },
    cariGrupSave(islem) {
      store.dispatch('cariGroupSave', {
        tur: islem === 'kayit' ? this.grupTuru.value : '',
        adi: islem === 'kayit' ? this.grupName : '',
        aciklama: islem === 'kayit' ? this.grupContent : '',
        cariGrupID: islem === 'edit' ? this.editData.cariGrupID : '',
        islem,
      })
          .then((res, position = 'bottom-right') => {
            this.addCari.cariGrupID = res.result.data.cariGrupID
            this.$refs.newCariGrup.hide()
            this.$bvModal.show('new-cari')
            this.grupName = ''
            this.grupContent = ''
            this.grupTuruDefault()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            this.addCari.cariGrupID = ''
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
  },
  mounted() {
    if (store.state.cariToYeniAlisCariID != null) {
      store
          .dispatch('cariDetay', {
            cariKartID: store.state.cariToYeniAlisCariID,
          })
          .then(res => {
            this.fatura.cariKart = res.data.cariData
          })
      store.state.cariToYeniAlisCariID = null
    }
    this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    store.dispatch('stokList', {
      uzunluk: 20,
      baslangic: 0,
    })
    store.dispatch('vergiListesi')
        .then(res => {
          const kdv = Object.values(res)
              .find(x => x.vergiKodu === this.kdvID)
          this.addVergiData(kdv.kodID, kdv.vergiOrani)
        })
    store.dispatch('cariCardlar', {
      uzunluk: 20,
      baslangic: 0,
      cariGrupID: 0,
    })
    store.dispatch('cariGrup')
    store.dispatch('stokGrup')
    window.addEventListener('resize', this.initTrHeight)
  },
  beforeDestroy() {
    localStorage.removeItem('associateID')
    store.commit('CARI_GROUP_ACTION', 'remove')
    store.commit('CARI_CARD_ACTION', 'remove')
    store.commit('STOK_GROUP_ACTION', 'remove')
    store.commit('FATURA_LIST_ACTION', 'remove')
    store.commit('VERGI_ACTION', 'remove')
    store.commit('STOK_LIST_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
}
</script>
<style lang="scss">
.invoice-preview-card .card .card-header {
  display: none !important;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: 30px !important;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.vergi-input {
  width: 65% !important;
}

.miktar-input {
  max-width: 80% !important;
}

.vergi-remove-btn {
  display: contents !important;
}

.vergi-remove-btn .svg-inline--fa {
  padding-left: 20%;
}

.add-taxt {
  margin-top: 20px !important;
}

.repeater-form {
  transition: .35s height;
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

#sidebar-form {
  padding: 30px !important;
}

#new-cari-form {
  padding: 30px !important;
}

.cari-kart-text {
  margin-bottom: 0.4rem !important;
}

.empty-line {
  height: 10% !important;
}

.cari-kart {
  width: 100%;
  display: inline-block;
}

.item-checkbox {
  width: 40px !important;
}

.w-85 {
  width: 85% !important;
}

.cari-adi {
  width: 100%;
}

.table th, .table td {
  padding: 0.32rem 0.2rem !important;
}

@media screen and (min-width: 1320px) {
  .v-select-selected-xl {
    display: inline;
  }
}

.min-w-100 {
  min-width: 100px !important;
}

.ihale-badgee {
  display: flex;
  place-items: center;
  margin-left: 1rem;
  height: 26px;
}
</style>
